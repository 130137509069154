<template>
  <div class="ml-10 mr-11">
    <div class="d-flex align-items-center justify-space-between mb-2">
      <!-------- LEFT ITEMS ---------->
      <div class="d-flex flex-wrap align-center pr-5 filter-input-area">
        <!--  -->
        <div class="d-flex align-center">
          <span class="text-12px mr-2 title-work-break color-title"
            >対面日</span
          >
          <tvos-date-picker v-model="dates" :range="dateRange" />
        </div>
        <div class="d-flex align-center ml-5" v-if="filterPic">
          <span class="text-12px title-work-break color-title">{{
            $t('myPage.ExperienceLodging.salesRepresentative')
          }}</span>
          <pic-select
            v-model="picId"
            class="ml-2 mr-4 v-select-custom"
          ></pic-select>
        </div>
        <div class="d-flex align-center">
          <span class="text-12px title-work-break color-title"
            >対面方法</span
          >
          <action-method-select v-model="methodIds"
            class="ml-2 mr-4 v-select-custom" />
        </div>
        <div class="d-flex align-center">
          <div class="d-flex align-center">
            <span class="text-12px title-work-break color-title"
              >対面相手</span
            >
            <participant-select v-model="participants"
              class="ml-2 mr-4 v-select-custom" />
          </div>
          <div class="d-flex align-center">
            <span class="text-12px title-work-break color-title">を</span>
            <v-select
              :items="participantsTypeItems"
              v-model="participantsType"
              item-value="id"
              item-text="name"
              dense
              outlined
              style="width: 110px"
              hide-details
              class="ml-2 mr-4 v-select-custom"
            ></v-select>
          </div>
        </div>
      </div>

      <!-------- RIGHT ITEMS ---------->
      <div class="mt-2 title-work-break">
        <span class="text-12px mt-1 color-title">総計</span>
        <span class="text-18px" style="color: #0b6786">{{
          totalCount
        }}</span>
        <span class="text-12px mt-1" style="color: #0b6786">{{
          $t('commons.item')
        }}</span>
      </div>
    </div>
    <Table
      :attr="{
        dense: true,
        'item-key': 'id',
      }"
      ref="table"
      :items="items"
      :headers="headers"
      :itemsPerPage="30"
      :total="totalCount"
      :itemsPerPageOptions="[30, 50, 100, 200, 500]"
      :funReset="search"
      :filter="filter"
      :multi-sort="true"
      :sortField="[]"
    >
      <template v-slot:[`item.LatestAdvertisingMeasures`]="{ item }">
        <div class="text-overflow">
          <span>{{ item.LatestAdvertisingMeasures }}</span>
        </div>
      </template>
      <template v-slot:[`item.lastBooking.checkInDate`]="{ item }">
        <div class="text-overflow">
          <span>{{ item.lastBooking ? item.lastBooking.checkInDate : ''}}</span>
        </div>
      </template>
      <template v-slot:[`item.picName`]="{ item }">
        <div>
          {{ item.pic ? item.pic.name : '' }}
        </div>
      </template>
      <template v-slot:[`item.actionMethodId`]="{ item }">
        <div v-for="val in clientActionHistoryList" :key="val.id">
          <span
            v-if="val.id === item.lastClientActionHistory.actionMethodId"
            >{{ val.name }}</span
          >
        </div>
      </template>
      <template v-slot:[`item.lastClientActionHistoryActionAt`]="{ item }">
        <div v-if="item.lastClientActionHistory">
          {{
            item.lastClientActionHistory.actionAt | formatDate
          }}
        </div>
      </template>
      <template v-slot:[`item.lastClientActionHistoryPlannedAt`]="{ item }">
        <div v-if="item.lastClientActionHistory">
          {{
            item.lastClientActionHistory.actionPlannedAt | formatDateTime({noSecond: true})
          }}
        </div>
      </template>
      <template
        v-slot:[`item.lastClientActionHistoryParticipants`]="{ item }"
      >
        <div v-if="item.lastClientActionHistory">
          <span
            v-for="(val, idx) in item.lastClientActionHistory
              .participants"
            :key="idx"
          >
            {{ val }}
            <span
              v-if="
                idx < item.lastClientActionHistory.participants.length - 1
              "
              >,</span
            >
          </span>
        </div>
      </template>
      <template v-slot:[`item.LatestActivityStatus`]="{ item }">
        <div class="text-overflow">
          <span>{{ item.LatestActivityStatus }}</span>
        </div>
      </template>
      <template v-slot:[`item.ItemsShared`]="{ item }">
        <div class="text-overflow">
          <span>{{ item.ItemsShared }}</span>
        </div>
      </template>
      <template v-slot:[`item.CustomerDetails`]="{ item }">
        <v-btn
          icon
          class="t-btn--prm"
          @click="checkPerUser ? $openRoute.client(item.id) : ''"
        >
          <v-icon> mdi-human-handsup</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.isCompany`]="{ item }">
        {{ item.isCompany ? '法人' : ' 個人' }}
      </template>
      <template v-slot:[`item.numDate`]="{ item }">
        {{
          calculateDays(
            item.lastBooking.checkOutDate,
            item.lastBooking.checkInDate,
          ) + 1
        }}
      </template>
    </Table>
  </div>
</template>

<script>
import { calculateDays } from '@/utils/dateUtil.js';
import Table from '@/components/Table/index.vue';
import TvosDatePicker from '@/components/Input/TvosDatePicker';
import PicSelect from '@/components/Input/PicSelect'
import ActionMethodSelect from '@/components/Input/ActionMethodSelect'
import ParticipantSelect from '@/components/Input/ParticipantSelect'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'FaceToFaceTable',
  components: { Table, TvosDatePicker, PicSelect, ActionMethodSelect, ParticipantSelect },
  props: {
    dateRange: Boolean,
    filterPic: Boolean,
  },
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      participantsType: 'Any',
      totalCount: 0,
      picId: this.$store.getters.getUserId,
      methodIds: [],
      participants: [],
      items: [],
      participantsTypeItems: [
        { id: 'Any', name: 'いずれかを含む' },
        { id: 'All', name: '全てを含む' },
      ],
      dates: this.dateRange ? [
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 2,
          )
            .toISOString()
            .substr(0, 10),
        new Date(
            new Date().getFullYear() + 1,
            new Date().getMonth(),
            new Date().getDate() + 2,
          )
            .toISOString()
            .substr(0, 10)
      ] : new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 2,
          )
            .toISOString()
            .substr(0, 10),
      headers: [
        {
          text: '個人/法人',
          value: 'isCompany',
          width: '53px',
          fieldName: 'client.isCompany'
        },
        {
          text: '顧客名',
          value: 'name',
          width: '125px',
          sortable: false,
        },
        {
          text: '営業担当',
          value: 'picName',
          width: '56px',
          fieldName: 'pic.name'
        },
        {
          text: '最新対面方法',
          value: 'actionMethodId',
          width: '83px',
          fieldName: 'clientActionHistory.actionMethodId'
        },
        {
          text: '最新対面折衝日',
          value: 'lastClientActionHistoryActionAt',
          width: '76px',
          fieldName: 'clientActionHistory.actionAt'
        },
        {
          text: '最新対面折衝予定日',
          value: 'lastClientActionHistoryPlannedAt',
          width: '76px',
          fieldName: 'clientActionHistory.actionPlannedAt'
        },
        {
          text: '最新対面相手',
          value: 'lastClientActionHistoryParticipants',
          width: '98px',
          fieldName: 'clientActionHistory.participants'
        },
        {
          text: '顧客詳細',
          value: 'CustomerDetails',
          align: 'center',
          width: '48px',
          sortable: false
        },
      ],
    };
  },
  computed: {
    filter(){
      return {
        lastActionDateFrom: this.dateRange ? this.dates[0] : this.dates,
        lastActionDateTo: this.dateRange ? this.dates[1] : null,
        hasActionMethodIds: this.methodIds,
        hasActionParticipants: this.participants,
        hasActionParticipantsType: this.participantsType,
        picIds: this.picId && this.filterPic ? [this.picId] : null,
      }
    },
    clientActionHistoryList () {
      return this.$store.getters.clientActionHistoryList
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$refs.table.reset();
      }
    }
  },
  mounted() {
    this.$refs.table.reset();
    this.$store.dispatch('getClientActionHistoryListMeeting')
  },

  methods: {
    calculateDays,
    async search(variables) {
      if(variables){
        const filter = this.filter;
        if (filter.hasActionMethodIds.length === 0) return; // required
        if (filter.hasActionParticipants.length === 0) return; // required
        
        const {items, total} = await this.$store.dispatch('getSearchClientListMeeting', {
          filter: this.filter,
          take: variables.pagination.take,
          skip: variables.pagination.skip,
          orderBy: variables.orderBy
        });
        this.totalCount = total;
        this.items = items;
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.filter-input-area {
  &>* {
    margin-bottom: 1em;
  }
}
</style>