<template>
  <div class="mt-4">
    <face-to-face-table dateRange/>
  </div>
</template>

<script>
import FaceToFaceTable from './FaceToFaceTable.vue';

export default {
  name: 'FaceToFaceCustomer',
  components: { FaceToFaceTable },
}
</script>
