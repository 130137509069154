<template>
  <div class="d-flex align-center">
    <menu-date-picker v-model="fromDate" />
    <template v-if="range">
        <span class="mx-1 noto-sans_16px">〜</span>
        <menu-date-picker v-model="toDate" />
    </template>
    <template v-else>
      <span class="text-12px title-work-break color-title">以降</span>
    </template>
  </div>
</template>

<script>
import MenuDatePicker from './MenuDatePicker.vue'
export default {
  components: { MenuDatePicker },
  props: {
    value: [String, Date, Array],
    range: Boolean
  },
  computed: {
    fromDate: {
      get() {
        return this.range ? this.value?.[0] : this.value
      },
      set(v) {
        this.emitInput(v, this.toDate)
      }
    },
    toDate: {
      get() {
        return this.range ? this.value?.[1] : null
      },
      set(v) {
        this.emitInput(this.fromDate, v)
      }
    },
  },
  methods: {
    emitInput(fromDate, toDate) {
      if (this.range) {
        this.$emit('input', [fromDate, toDate])
      } else {
        this.$emit('input', fromDate)
      }
    }
  }
}
</script>
