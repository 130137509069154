<template>
  <div>
    <v-icon small>mdi-calendar-month</v-icon>
    <v-btn
      outlined
      small
      color="black"
      class="mx-1"
      hide-details
      v-on="$listeners"
    >
      <span>{{ date }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    date: String
  }
}
</script>
