<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <date-picker-button :date="value" v-on="on" />
    </template>
    <!-- Date from -->
    <v-date-picker
      :value="value"
      @change="onInput"
      :first-day-of-week="0"
      :locale="$i18n.locale"
      scrollable
      class="v-date-picker-custom"
    ></v-date-picker>
    <!--  -->
  </v-menu>
</template>

<script>
import DatePickerButton from './DatePickerButton.vue'
export default {
  components: { DatePickerButton },
  props: {
    value: [String, Date]
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    onInput (v) {
      this.menu = false
      this.$emit('input', v)
    }
  }
}
</script>
