<template>
  <v-select
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    multiple
    item-text="name"
    item-value="name"
    dense
    outlined
    hide-details
  >
  </v-select>
</template>

<script>
import gql from 'graphql-tag'
export default {
  props: {
    value: [String, Array],
  },
  data () {
    return {
      items: []
    }
  },
  watch: {
    items () {
      this.$emit('input', this.items.map(item => item.name))
    }
  },
  apollo: {
    items: {
      query: gql`
        query{
          enumParticipantTypeList{
            id
            name
          }
        }`,
      update: data => data.enumParticipantTypeList
    }
  }
}
</script>
