<template>
  <v-select
    :items="staffList"
    :value="value"
    @input="$emit('input', $event)"
    item-text="name"
    item-value="id"
    dense
    outlined
    hide-details
    class="pic-select"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  data () {
    return {
      staffList: []
    }
  },
  async mounted() {
    const list = await this.$store.dispatch('actionStaffList')
    this.staffList = [{id: null, name: '指定なし'}].concat(list)
  }
}
</script>

<style lang="scss" scoped>
.pic-select {
  width: 100px;
}
</style>
